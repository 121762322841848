@font-face {
  font-family: 'Montserrat-Regular';
  src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'Oswald-Regular';
  src: url('assets/fonts/Oswald/Oswald-Regular.ttf');
}
body {
  font-family: 'Montserrat-Regular';
  font-size: 30px;
}
h1 {
  font-family: 'Oswald-Regular';
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
}
h2 {
  font-family: 'Oswald-Regular';
  font-size: 42px;
  line-height: 42px;
  font-weight: 700;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 0.2em;
  height: 0.1em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.00),
}


::-webkit-scrollbar-thumb:vertical,::-webkit-scrollbar-thumb:horizontal
{
        background: rgba(196, 196, 196, 1);
        borderRadius: 20px;
        height: 0.1em;
}
