#simple-popover .MuiPopover-paper, #no-popover .MuiPopover-paper{
  max-width:344px !important;
  @media only screen and (max-width: 600px) {
    border-radius: 28px !important;
    right: calc(50% - 123px) !important;
}
right: 230px !important;
left: unset !important;
}

#notifications .MuiListItem-root{
  display: block !important;
  &::before {
    position: absolute;
    background: #000;
    width: 8px;
    height: 8px;
    content: '';
    border-radius: 50%;
    left: -28px;
    top: 15px;
  }
  padding-left: 0px !important;
}
