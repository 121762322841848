.header-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px !important;
}

.menu-bar > li > .menu-link[aria-haspopup=true]:after{
  margin-right: 23px !important;
  margin-top: -1px !important;
  margin-left: 8px !important;
}


#headerItem2,
#headerItem3,
#headerItem4,
#headerItem5,
#headerItem6,
#headerItem7 {
  padding: 0 12px 12px !important;
}

#headerItem1 {
  padding: 0 12px 8px !important;
  @media screen and (max-width: 768px) {
    padding: 0 12px 12px !important;
  }
}

.MuiMenu-paper{
  margin-top:0px !important;
}

.MuiAppBar-root {
  background-color: #0C3752 !important;
}