.custom_editor p {
            color: "red";
            font-feature-settings: "'clig' off, 'liga' off";
            font-family: "Montserrat-Regular !important";
            font-size: "18px !important";
            font-weight: "300 !important";
            line-height: "32px !important";
}
#footer-detail{
    position: relative;
}