$color-sky: #f1f9fe;
$color-accent: #183750;
$color-light: #ffffff;
$color-grey: #747679;
$color-link: #417bb0;
.app-list {
 // border-bottom: 1px solid #E7E7E7;
  a {
    color: $color-grey;
    font-weight: 600;
    //border-bottom: 1px solid #E7E7E7;
    &:hover {
      color: $color-accent;
    }
  }
  &:hover {
    > [aria-haspopup="true"] {
      background-color: $color-sky;
      color: $color-accent;
    }
  }
}