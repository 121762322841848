.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.react-player {
    /*Player ratio: 100 / (1280 / 720) */
    position: absolute;
    top: 0;
    left: 0;
}