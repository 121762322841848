#feedback .MuiPopover-paper {
    // top: 128px !important;
    // left: 896px !important;
    // right: 50px !important;
    // width: 350px !important;

    // For small screens only
    @media only screen and (max-width: 600px) {
        border-radius: 28px;
    }
}
