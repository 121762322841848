$color-accent: #183750;
$color-light: #ffffff;
$color-sky: #f1f9fe;
$color-grey: #747679;
$color-link: #417bb0;
$menu-link-padding: 15px 20px;
// $mega-menu-multiLevel-colWidth: 100/3 + 0%;
$mega-menu-multiLevel-colWidth: 25%;
$breakpoint: 950px;
$mobileScreen: 768px;
$mobile-menu-back-height: "calc(1.4em + 40px)";
$mobile-menu-back-offset: "calc(0px - (1.4em + 40px))";


// General styling
nav {

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: block;
    text-decoration: none;

    &:hover,
    &:visited {
      text-decoration: none;
    }

    &[aria-haspopup="true"] {
      ~ul {
        display: none;
      }
    }
  }
}

// Shared styles for all links
.menu-bar {
  // background: $color-light;
  background-color: transparent;
  min-width: 118px;
}

.arrow-icon:hover {
  transform: scale(1.2);
}

.menu-link {
  padding: $menu-link-padding;
  //   background: $color-light;
  color: $color-light;
  font-size: 14px;
  transition: background 0.2s, color 0.2s;

  &:hover {
    // background: $color-accent;
    color: $color-light;
    // .mega-menu & {
    //   background: tint($color-accent, 85%);
    //   color: darken($color-accent, 5%);
    // }
  }

  &[aria-haspopup="true"] {
    ~ul {
      display: none;
      height: 100vh;
      z-index: 9999;
    }

    &:after {
      content: "\203A";
      display: inline-block;
      font-size: 1.5em;
      float: right;
      line-height: 1;
      margin-top: 0px; // Font offset

      .menu-bar>li>& {
        margin-top: 0;
        margin-left: 30px;
        transform-origin: center;
        transform: rotate(90deg);
        background: transparent;

        @media screen and (max-width: $mobileScreen) {
          display: none;
        }
      }
    }
  }
}

.mega-menu--multiLevel>section>li>.active {
  background-color: $color-light;
  color: $color-accent;
  font-weight: 900;
}

.mega-menu--multiLevel>section>li>.active {
  background-color: $color-light;
  color: $color-accent;
  font-weight: 900;
}

.mega-menu--multiLevel>section>li:hover>[aria-haspopup="true"]~ul li>.active {
  background-color: $color-sky;
  color: $color-accent;

  .app-list {
    background-color: $color-sky;
  }
}

.menu-bar>section>li:hover>[aria-haspopup="true"]~ul li>.active {
  background-color: $color-sky;
  color: $color-accent;
}

.mega-menu--multiLevel>section>li:hover li>.active~ul {
  display: flex;
  flex-direction: column;
}

.menu-bar {
  >li:first-child {
    &:hover {
      >[aria-haspopup="true"] {
        color: $color-light;
      }
    }
  }

  >li {
    &:hover {
      >[aria-haspopup="true"] {
        //   background: $color-accent;
        color: $color-accent;
        background-color: transparent;
      }
    }

    >[aria-haspopup="true"] {
      &:focus {
        ~ul {
          display: block;
          transform-origin: top;
          animation: dropdown 0.2s ease-out;
          height: 100vh;
        }
      }
    }

    &:focus-within {
      >[aria-haspopup="true"] {
        color: $color-light;

        ~ul {
          display: flex;
        }
      }
    }

    >[aria-haspopup="true"]:focus,
    &:focus-within>[aria-haspopup="true"],
    &:hover>a {
      &:after {
        transform: rotate(270deg);
        animation: 0.5;
      }
    }
  }

  .mega-menu {
    &:hover {
      display: block;
    }
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  40% {
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
  }
}

// Basic styling for the mega menu dropdown
.mega-menu {
  position: absolute;
  //top: 100%;
  top: 95.691px;
  left: 0;
  width: 100%;
  // background: $color-light;
  //overflow: hidden;
  //overflow-y: scroll;
  display: -webkit-flex !important;
  backdrop-filter: blur(10px);
}

.mega-menu section:first-child {
  margin-left: 10%;
  width: 25%;
  height: 100vh;
  background-color: $color-accent;
}

// .mega-menu div:nth-child(2) {
//   width: 40%;
// }
// .mega-menu div:nth-child(3) {
//   width: 40%;
// }
.mega-menu-header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
  color: darken($color-accent, 5%);
}

.mega-menu--multiLevel {
  flex-direction: column;

  [aria-haspopup="true"] {
    ~ul {
      position: absolute;
      top: 0;
      height: 100px;
      //border-left: 1px solid #f0f0f0;
    }
  }

  >section {
    // width: $mega-menu-multiLevel-colWidth;
    background-color: $color-accent;
    margin-left: 10%;
    padding-top: 2%;
    padding-right: 60%;

    >li {
      width: "25%";
      //width: $mega-menu-multiLevel-colWidth;
      background-color: $color-accent;
      color: $color-light;
      font-weight: 400;

      a {
        &:after {
          content: none !important;
          padding-right: 0%;
        }
      }

      // > [aria-haspopup="true"] {
      //   &:focus {
      //     ~ ul {
      //       display: block;
      //       transform-origin: top;
      //       animation: dropdown 0.2s ease-out;
      //       height: 100vh;
      //     }
      //   }
      // }
      // &:focus-within {
      //   > [aria-haspopup="true"] {
      //     color: $color-light;
      //     ~ ul {
      //       display: block;
      //     }
      //   }
      // }
      &:hover {
        >[aria-haspopup="true"] {
          background-color: $color-light;
          font-weight: 900;
          padding-right: 0%;
          color: darken($color-accent, 5%);

          ~ul {
            display: flex;
            transform-origin: left;
            animation: flyout 0.2s ease-out;
            flex-direction: column;
            height: 90vh;
          }

          ~ul.apps {
            display: flex;
            transform-origin: left;
            animation: flyout 0.2s ease-out;
            flex-direction: row;
            flex-wrap: wrap;
            height: 82vh;
            width: 56.5%;
            overflow-y: scroll;
          }

          @media screen and (max-width: $mobileScreen) {
            ~ul.apps {
              display: flex;
              transform-origin: left;
              animation: flyout 0.2s ease-out;
              flex-direction: row;
              flex-wrap: wrap;
              height: 82vh;
              width: 100%;
              overflow-y: scroll;
            }

            ~ul.department {
              display: flex;
              transform-origin: left;
              animation: flyout 0.2s ease-out;
              flex-direction: column;
              // flex-wrap: wrap;
              height: 82vh;
              width: 100%;
              overflow-y: scroll;
            }
          }
        }
      }

      >[aria-haspopup="true"] {
        ~ul {
          left: $mega-menu-multiLevel-colWidth;
          width: 30%;
          padding-left: 0%;
          padding-top: 3%;
          background-color: $color-light;
          margin-left: 10%;

          .dept-menu {

            a {
              color: $color-grey;
              font-weight: 600;

              //border-bottom: 1px solid #E7E7E7;
              &:hover {
                color: $color-accent;
              }
            }

            &:hover {
              >[aria-haspopup="true"] {
                background-color: $color-sky;
                color: $color-accent;
              }
            }
          }

          .dept-menu:after {
            content: "";
            display: block;
            margin: 0 auto;
            margin-right: 4.5%;
            width: 93%;
            border-bottom: 1px solid #E7E7E7;
          }

          ul {
            width: 100%;
            left: 100%;
            background-color: $color-sky;
            height: 100vh;
          }
        }
      }

      li {
        display: block;

        &:hover {
          >[aria-haspopup="true"] {
            ~ul {
              display: flex;
              flex-direction: column;
              //animation: flyout 0.2s ease-out;
            }
          }
        }
      }
    }
  }

  // > li {
  //   width: $mega-menu-multiLevel-colWidth;
  //   background-color: $color-accent;
  //   color: $color-light;
  //   &:hover {
  //     > [aria-haspopup="true"] {
  //       background-color: $color-light;
  //       ~ ul {
  //         display: flex;
  //         transform-origin: left;
  //         animation: flyout .2s ease-out;
  //         flex-direction: column;
  //         height: 100vh;
  //       }
  //     }
  //   }
  //   > [aria-haspopup="true"] {
  //     ~ ul {
  //       left: $mega-menu-multiLevel-colWidth;
  //       width: $mega-menu-multiLevel-colWidth;
  //       ul {
  //         width: 100%;
  //         left: 100%;
  //       }
  //     }
  //   }
  //   li {
  //     &:focus-within {
  //       > [aria-haspopup="true"] {
  //         ~ ul {
  //           display: flex;
  //           flex-direction: column;
  //         }
  //       }
  //     }
  //   }
  // }
  li {

    &:hover,
    &:focus-within {
      >[aria-haspopup="true"] {
        background: tint($color-accent, 85%);
        color: $color-light;
      }
    }
  }
}

.mega-menu--flat {
  >* {
    flex: 1;
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

.description ul li {
  color: $color-link;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  /* 100% */
}

.description ul li a,
.description ul li a:hover {
  color: $color-link !important;
}

.dec-heading {
  color: $color-accent !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
}

p {
  color: $color-grey;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  margin-bottom: 0px;
  white-space: pre-line;
}

.dec-related {
  color: $color-accent;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.15px;
  margin-top: 40px;
}

.apps {
  padding: 3% !important;
}

.apps li {
  display: flex !important;
  width: 14%;
  flex-wrap: wrap;
  justify-content: center;
  //align-items: center;
  text-align: center;
}

.apps li a {
  border-bottom: 0px !important;
}

// Hide mobile specific elements
.mobile-menu-trigger,
.mobile-menu-trigger,
.mobile-menu-back-item,
.mobile-menu-back-item-home {
  display: none;
}

.app-title {
  color: $color-accent;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 150% */
}

.app-subTitle {
  color: $color-accent;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 150% */
  text-align: left;
}

.flexProp {
  display: flex;
  align-items: center;
  gap: 20px;
}

// .mega-menu--multiLevel > section > li:nth-child(1) > .menu-list-link ~ ul.custom {
//   display: flex;
//   transform-origin: left;
//   animation: flyout 0.2s ease-out;
//   flex-direction: column;
//   height: 100vh;
// }

// .mega-menu--multiLevel > section > li > [aria-haspopup=true] ~ ul.custom li > .menu-list-link.active {
//   background-color: #f1f9fe;
//   color: #183750;
// }

// .mega-menu--multiLevel > section > li:nth-child(1) > .menu-list-link ~ ul.custom > li > ul {
//   display: flex;
//   flex-direction: column;
// }
@media screen and (max-width: $mobileScreen) {
  nav {
    .menu-bar {
      >li {
        >[aria-haspopup="true"] {
          &:focus {
            ~ul {
              display: block;
              transform-origin: top;
              animation: dropdown 0.2s ease-out;
              height: 100vh;
              margin-top: $mobile-menu-back-height;
            }
          }
        }

        &:focus-within {
          >[aria-haspopup="true"] {
            color: $color-light;

            ~ul {
              display: flex;
            }
          }
        }

        >[aria-haspopup="true"]:focus,
        &:focus-within>[aria-haspopup="true"],
        &:hover>a {
          &:after {
            transform: rotate(270deg);
            animation: 0.5;
          }
        }
      }
    }

    .mega-menu {
      top: 0;
      height: 100vh;
      flex-direction: column;

      section:first-child {
        margin-left: 0;
        width: 100%;
      }
    }

    .mega-menu--multiLevel {
      >section {
        margin-left: 0;
        padding-right: 0;
        width: 100%;

        >li {
          width: 100%;

          >[aria-haspopup="true"] {
            ~ul {
              width: 100%;
              left: 0;
              padding-left: 0;
              margin-left: 0;
            }
          }

          >[aria-haspopup="true"] {
            ~ul.department {
              background-color: $color-light;

              .dept-menu {
                a {
                  color: $color-grey;
                  font-weight: 600;

                  &:hover {
                    color: $color-accent;
                  }
                }

                &:hover {
                  >[aria-haspopup="true"] {
                    background-color: $color-sky;
                    color: $color-accent;
                  }
                }
              }

              .dept-menu:after {
                content: "+";
                color: #183750;
                display: flex !important;
                flex-direction: row-reverse;
                position: relative !important;
                top: -38px;
                left: 50px;
                border-bottom: none;
                height: 5px;
              }
            }
          }
        }
      }
    }
  }

  .mobile-menu-back-item {
    display: block;
    order: -1;
    font-size: 14px;
    padding: 15px 20px;

    a {
      // background: tint(grey, 70%);
      color: $color-accent;
      // max-height: $mobile-menu-back-height;
      // margin-top: $mobile-menu-back-offset;
      pointer-events: auto;

      &:before {
        content: "\2039";
        margin-right: 10px;
      }
    }
  }

  .mobile-menu-back-item-home {
    display: block;
    order: -1;
    font-size: 14px;
    // padding: 10px 15px;

    a {
      // background: tint(grey, 70%);
      color: $color-light;
      // max-height: $mobile-menu-back-height;
      // margin-top: $mobile-menu-back-offset;
      pointer-events: auto;

      &:before {
        content: "\2039";
        margin-right: 10px;
      }
    }
  }

}